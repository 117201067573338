<template>
  <div>
    <b-modal
      id="modal-input-gudang-gudang"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Gudang <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_gudang')"
            type="text"
            v-model="$v.is_data.nama_gudang.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tipe Gudang <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('tipe_gudang')"
            type="text"
            v-model="$v.is_data.tipe_gudang.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Utama <span class="text-danger">*</span>
          </template>
          <b-form-select type="text" v-model="is_gudang_utama" @change="ubahUtama()">
            <b-form-select-option :value="true">Ya</b-form-select-option>
            <b-form-select-option :value="false">Tidak</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
          <template v-slot:label> Poli <span class="text-danger">*</span> </template>
          <b-form-select :options="option_poli" v-model="ms_poli_id"></b-form-select>
        </b-form-group>

        <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
          <template v-slot:label> Kode Poli </template>
          <b-form-input
            readonly
            type="text"
            v-model="$v.is_data.kode_poli_bpjs.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
          <template v-slot:label> Nama Poli </template>
          <b-form-input
            readonly
            type="text"
            v-model="$v.is_data.nama_poli_bpjs.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
          <template v-slot:label>
            Gudang Utama <span class="text-danger">*</span>
          </template>
          <b-form-select
            :disabled="is_gudang_utama"
            type="text"
            v-model="is_data.ms_gudang_utama_id"
            :options="option_gudang"
          >
          </b-form-select>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-gudang-gudang')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: ["fileName", "option_gudang", "option_poli"],
  data() {
    return {
      is_data: {
        nama_gudang: null,
        tipe_gudang: null,
        ms_poli_id: null,
        kode_poli_bpjs: null,
        nama_poli_bpjs: null,
        ms_gudang_utama_id: null,
      },
      is_gudang_utama: true,
      ms_poli_id: "",
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_gudang: { required },
      tipe_gudang: { required },
      kode_poli_bpjs: {  },
      nama_poli_bpjs: {  },
      ms_poli_id: { 
        required: requiredIf(function() {
          return !this.is_gudang_utama
        }) 
      },
      ms_gudang_utama_id: { 
        required: requiredIf(function() {
          return !this.is_gudang_utama
        }) 
      },
    },
  },
  watch: {
    ms_poli_id(nv, ov) {
      let x = this.option_poli.find((o) => o.value === nv);
      console.log(x);
      if (x != undefined) {
        console.log(x)
        this.is_data.kode_poli_bpjs = x.kode_poli_bpjs;
        this.is_data.nama_poli_bpjs = x.nama_poli_bpjs;
        this.is_data.ms_poli_id = x.value
      }
      console.log('this.is_data', this.is_data)
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    ubahUtama(){
      const vm = this
      const status = vm.is_gudang_utama
      if(status){
        vm.is_data.ms_gudang_utama_id = null
      }else{
        vm.is_data.ms_poli_id = null
        vm.is_data.kode_poli_bpjs = null
        vm.is_data.nama_poli_bpjs = null
        vm.ms_poli_id = null
      }
      console.log(vm.is_gudang_utama)
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/register`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-input-gudang-gudang");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_gudang: null,
        tipe_gudang: null,
        ms_gudang_utama_id: null,
        ms_poli_id: null,
        kode_poli_bpjs: null,
        nama_poli_bpjs: null,
        is_gudang_utama: null,
      };
      this.is_gudang_utama = true
      this.ms_poli_id = "";
    },
  },
};
</script>
