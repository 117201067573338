<template>
  <div>
    <b-modal
      id="modal-edit-gudang"
      size="lg"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
      v-if="is_data"
    >
      <b-form-group label-cols-md="3">
        <template v-slot:label> Nama Gudang <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('nama_gudang')"
          type="text"
          v-model="$v.is_data.nama_gudang.$model"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label> Tipe Gudang <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('tipe_gudang')"
          type="text"
          v-model="$v.is_data.tipe_gudang.$model"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label> Utama <span class="text-danger">*</span> </template>
        <b-form-select type="text" v-model="is_gudang_utama" @change="ubahUtama()">
          <b-form-select-option :value="true">Ya</b-form-select-option>
          <b-form-select-option :value="false">Tidak</b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
        <template v-slot:label> Poli <span class="text-danger">*</span> </template>
        <b-form-select :options="option_poli" v-model="$v.is_data.ms_poli_id.$model"></b-form-select>
      </b-form-group>

      <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
        <template v-slot:label> Kode Poli <span class="text-danger">*</span> </template>
        <b-form-input
          readonly
          type="text"
          v-model="$v.is_data.kode_poli_bpjs.$model"
          placeholder="-"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
        <template v-slot:label> Nama Poli <span class="text-danger">*</span> </template>
        <b-form-input
          readonly
          type="text"
          v-model="$v.is_data.nama_poli_bpjs.$model"
          placeholder="-"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3" v-if="!is_gudang_utama">
        <template v-slot:label>
          Gudang Utama <span class="text-danger">*</span>
        </template>
        <b-form-select
          :disabled="is_gudang_utama"
          type="text"
          v-model="is_data.ms_gudang_utama_id"
          :options="option_gudang"
        >
        </b-form-select>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-gudang')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalEdit",
  props: ["fileName", "data_edit", "option_gudang", "option_poli"],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
      is_gudang_utama: true
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
      if (newVal.ms_gudang_utama_id) {
        this.is_gudang_utama = false
      }
      // console.log(this.is_gudang_utama)
      // console.log(this.is_data)
      // for (let i = 0; i < this.option_kelas_terapi.length; i++) {
      //   const el = this.option_kelas_terapi[i];
      //   if (el.value === this.is_data.ms_kelas_terapi_id) {
      //     this.is_data.ms_kelas_terapi_id = el
      //   }
      // }
    },
    is_gudang_utama(newVal) {
      if (newVal) {
        this.is_data.ms_gudang_utama_id = null
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_gudang: { required },
      tipe_gudang: { required },
      kode_poli_bpjs: {  },
      nama_poli_bpjs: {  },
      ms_poli_id: { 
        required: requiredIf(function() {
          return !this.is_gudang_utama
        }) 
      },
      ms_gudang_utama_id: { 
        required: requiredIf(function() {
          return !this.is_gudang_utama
        }) 
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    ubahUtama(){
      const vm = this
      const status = vm.is_gudang_utama
      if(status){
        vm.is_data.ms_gudang_utama_id = null
      }else{
        vm.is_data.ms_poli_id = null
        vm.is_data.kode_poli_bpjs = null
        vm.is_data.nama_poli_bpjs = null
        vm.ms_poli_id = null
      }
      console.log(vm.is_gudang_utama)
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-gudang");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_gudang: null,
        tipe_gudang: null,
        ms_gudang_utama_id: null,
        ms_poli_id: null,
        kode_poli_bpjs: null,
        nama_poli_bpjs: null,
        is_gudang_utama: null,
      };
    },
  },
};
</script>
